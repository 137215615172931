/* eslint-disable max-len */

import type { ApiOldLangPack } from '../api/types';

export const fallbackLangPackInitial = {
  WrongNumber: 'Wrong number?',
  SentAppCode: '我们已将验证码发送到您其他设备上的 Telegram 应用程序',
  'Login.JustSentSms': 'We have sent you a code via SMS. Please enter it above.',
  'Login.Header.Password': 'Enter Password',
  'Login.EnterPasswordDescription': '您启用了两步验证，因此您的账户受到额外密码的保护',
  StartText: '请确认您的国家/地区代码并输入您的手机号码',
  'Login.PhonePlaceholder': '你的电话号码',
  'Login.Next': '下一步',
  'Login.QR.Login': '二维码登录',
  'Login.QR.Title': '通过二维码登录 Telegram',
  'Login.QR.Help1': '在手机上打开 Telegram',
  'Login.QR.Help2': '转到 > 设置 > 设备 > 链接桌面设备',
  'Login.QR2.Help2': '转到 > 设置 > 设备 > 链接桌面设备',
  'Login.QR.Help3': '将手机指向此屏幕以确认登录',
  'Login.QR.Cancel': '通过电话号码登录',
  YourName: '您的名字',
  'Login.Register.Desc': 'Enter your name and add a profile picture.',
  'Login.Register.FirstName.Placeholder': 'First Name',
  'Login.Register.LastName.Placeholder': 'Last Name',
  'Login.SelectCountry.Title': 'Country',
  lng_country_none: 'Country not found',
  PleaseEnterPassword: 'Enter your new password',
  PHONE_NUMBER_INVALID: '无效的电话号码',
  PHONE_CODE_INVALID: '无效的验证码',
  PASSWORD_HASH_INVALID: '密码错误',
  PHONE_PASSWORD_FLOOD: '超出限制，请稍后重试',
  PHONE_NUMBER_BANNED: '此电话号码已被禁止',
} as ApiOldLangPack;
